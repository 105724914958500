<template>
  <div>
    <div class="orderboxs">
      <div class="management">
        <el-card class="management_basics management——tui">
          <div class="management-card-head" slot="header">
            <p>基础设置</p>
          </div>
          <div class="management_basics_body">
            <div class="management_basics_form">
              <div
                class="management_basics_form_item d-flex align-items-center"
              >
                <div class="management_basics_form_label">渠道状态</div>
                <div class="management_basics_form_content">
                  <el-radio-group v-model="ditchData['pc.open']">
                    <el-radio :label="0">关闭</el-radio>
                    <el-radio :label="1">开启</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div
                class="management_basics_form_item d-flex align-items-center"
              >
                <div class="management_basics_form_label">默认地址</div>
                <div class="management_basics_form_content">
                  <a
                    class="management_basics_form_text"
                    :href="ditchData['pc.default.url']"
                    target="_blank"
                    >{{ ditchData["pc.default.url"] }}</a
                  >
                </div>
              </div>
              <div
                class="management_basics_form_item d-flex align-items-center"
              >
                <div class="management_basics_form_label">默认地址</div>
                <div
                  class="management_basics_form_content management_basics_form_text"
                >
                  <p class="management_basics_form_text" @click="deployInput">
                    配置域名
                  </p>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <div class="management_basics" v-if="false">
          <div class="management-card-head">
            <p>微信开放平台设置</p>
          </div>
          <div class="management_basics_body">
            <el-form
              :model="wechatForm"
              ref="wechatForm"
              :rules="wechatRules"
              label-width="160px"
            >
              <el-form-item label="微信扫码登录">
                <el-radio-group v-model="wechatForm.wechat_scan">
                  <el-radio :label="0">关闭</el-radio>
                  <el-radio :label="1">开启</el-radio>
                </el-radio-group>
              </el-form-item>
              <template v-if="wechatForm.wechat_scan == 1">
                <el-form-item label="AppId" prop="appId">
                  <div class="wechatAppid">
                    <el-input v-model="wechatForm.appId"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="AppSecret" prop="appSecret">
                  <div class="wechatAppid">
                    <el-input v-model="wechatForm.appSecret"></el-input>
                  </div>
                </el-form-item>
              </template>
            </el-form>
          </div>
        </div>
        <el-card class="management_basics">
          <div class="management-card-head" slot="header">
            <p>推广设置</p>
          </div>
          <div class="management_basics_body">
            <el-form :model="promotion" ref="promotion" label-width="160px">
              <el-form-item label="网站标题">
                <div class="wechatAppid">
                  <el-input
                    v-model="ditchData['pc.promotion.title']"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="网站图标">
                <div class="d-flex align-items-center">
                  <div
                    class="skeletonize"
                    v-if="!ditchData['pc.promotion.icon']"
                    @click.stop="uploadImg('pc.promotion.icon')"
                  >
                    <i class="el-icon-plus"></i>
                  </div>
                  <div
                    class="skeletonize_img"
                    v-else
                    @click.stop="uploadImg('pc.promotion.icon')"
                  >
                    <img :src="ditchData['pc.promotion.icon']" alt="" />
                  </div>
                </div>
                <div class="skeletonize_toast">建议尺寸:100x100</div>
              </el-form-item>
              <el-form-item label="网站描述">
                <div class="wechatAppid">
                  <el-input
                    v-model="ditchData['pc.promotion.describe']"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="网站关键词">
                <div class="wechatAppid">
                  <el-input
                    v-model="ditchData['pc.promotion.keyword']"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="工具代码">
                <div class="d-flex flex-wrap">
                  <div class="wechatAppid instrumentCode">
                    <el-input type="textarea" v-model="ditchData['pc.promotion.tool_code']" show-word-limit
                      resize="none"></el-input>
                    <div class="skeletonize_toast">
                      支持CNZZ网站统计、百度统计等，请前往相应网站统计后台，获取代码。
                    </div>
                  </div>
                  <div class="example_code">
                    示例代码：
                    <p class="exaple_title">{{ exampleStart }}</p>
                    <p class="exaple_title">{{ exampleCode }}</p>
                    ....
                    <p class="exaple_title">{{ exampleEnd }}</p>
                    <p class="exple_sutitle">‘https://www.baidu.com’为你所要添加的路径</p>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
        <diy-footer-button v-if="hasPerm(['pc.setting.update'])">
          <el-button
            type="primary"
            @click.stop="preview"
            :loading="caijiLoading"
            >保存</el-button
          >
        </diy-footer-button>
      </div>
    </div>

    <el-dialog title="添加域名" :visible.sync="adddeploy" width="30%">
      <div class="addDoName">
        <div class="d-flex align-items-center addDoName_input">
          <div class="addDoName_input_label">域名</div>
          <el-input
            maxlength="255"
            v-model="addDoName_url"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click.stop="adddeploy = false;addDoName_url = '';"> 取 消</el-button>
        <el-button type="primary"  @click.stop="addDoNameSubmit">提 交</el-button>
      </div>
    </el-dialog>
    <pictureSelect
      ref="pictureSelect"
      :multipled="is_multiple"
      @change="imgbrand($event)"
    ></pictureSelect>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["type"],
  data() {
    return {
      channer_type: 0,
      deploy: false, //配置域名的弹窗
      domainData: [
        {
          url: "https://demo.oeob.net/",
          is_defalut: false,
        },
        {
          url: "https://demo.oeob.net/",
          is_defalut: false,
        },
      ], //域名列表
      adddeploy: false, //添加域名的弹窗
      addDoName_url: "", //添加域名
      wechatRules: {
        appId: {
          required: true,
          message: "请输入AppId",
          trigger: "blur",
        },
        appSecret: {
          required: true,
          message: "请输入AppSecret",
          trigger: "blur",
        },
      },
      wechatForm: {
        wechat_scan: 1, //微信扫码的开启
        appId: "",
        appSecret: "",
      },
      promotion: {
        title: "", //网站标题
        describe: "", //网站描述
        keyword: "", //网站关键词
        tool_code: "", //工具代码
      }, //推广
      ditchData: {},
      channerType: "",
      is_multiple: 0,
      caijiLoading:false,
      exampleStart:'<s'+"cript>",
      exampleCode: "var _hmt = _hmt || [];(function() {var hm = document.createElement('script');hm.src = 'https://www.baidu.com';var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(hm, s);})();",
      exampleEnd:'</s'+"cript>",
    };
  },
  created() {},
  mounted() {
    this.$store.commit("SET_MENU_TYPE", "pc");
    this.getChannelDitch();
  },
  methods: {
    getChannelDitch() {
      let that = this;
      this.$get(this.$apis.getPcTrench)
        .then((res) => {
          that.ditchData = res.data;
        })
        .catch((err) => {
          //console.log(err);
          that.common.message(that, err.message);
        });
    },
    uploadImg(type) {
      this.channerType = type;
      this.$refs.pictureSelect.photoVisible =
        !this.$refs.pictureSelect.photoVisible;
    },
    imgbrand(value) {
      this.ditchData["" + this.channerType] = value[0].file_url;
    },
    addDoNameSubmit() {
      this.ditchData["pc.default.url"] = this.addDoName_url;
      this.adddeploy = false;
      this.addDoName_url = "";
    },
    deployInput() {
      this.addDoName_url = this.ditchData["pc.default.url"];
      this.adddeploy = !this.adddeploy;
    },
    preview() {
      let that = this;
      let data = this.ditchData;
      this.caijiLoading=true;
      this.$put(this.$apis.getPcTrench, data)
        .then((res) => {
          that.caijiLoading=false;
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "保存成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
          } else {
            that.common.message(that, res.message);
          }
        })
        .catch((err) => {
          that.caijiLoading=false;
          that.common.message(that, err.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.orderboxs {
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
}

.management {
  flex: 1;
}

.management_basics {
  background: #fff;
  border-radius: 4px;
  position: relative;
  transition: all 0.2s ease-in-out;
  // margin-bottom: 24px;
}

.management——tui {
  margin-bottom: 24px;
}

.management-card-head {
  // border-bottom: 1px solid #f2f2f2;
  // padding: 8px 20px;
  line-height: 1;
}

.management-card-head p {
  display: inline-block;
  width: auto;
  line-height: 28px;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}

.management_basics_body {
  // padding: 20px;
}

.management_basics_form_item {
  margin-bottom: 30px;
  vertical-align: top;
  zoom: 1;
}

.management_basics_form .management_basics_form_item:last-child {
  margin-bottom: 0;
}

.management_basics_form_label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #666;
  line-height: 1;
  padding: 10px 20px 10px 0;
  box-sizing: border-box;
  width: 160px;
}

.management_basics_form_text {
  color: var(--fontColor);
  font-size: 14px;
  cursor: pointer;
}

.wechatAppid .el-input {
  width: 300px;
}

.skeletonize {
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin-right: 10px;
  border: 1px dashed #ededed;
  text-align: center;
  color: #dad9d9;
  float: left;
  position: relative;
  cursor: pointer;
  background: white;
}
.skeletonize_img {
  width: 100px;
  height: 100px;
  img {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }
}

.skeletonize i {
  font-size: 20px;
}

.skeletonize_toast {
  font-size: 12px;
  color: #999;
  padding: 4px 0;
  box-sizing: border-box;
  line-height: 1;
}

.wechatAppid .el-textarea {
  min-height: 226px;
  width: 300px;
}

.wechatAppid .el-textarea .el-textarea__inner {
  min-height: 226px !important;
}

.management_foot {
  position: fixed;
  left: 240px;
  right: 0;
  bottom: 0;
  min-width: 980px;
  margin: 0 24px;
  height: auto;
  border-top: 1px solid #f1f1f1;
  z-index: 100;
  background: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.management_foot_body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

/* 域名配置的弹窗 */
.domain_wrap {
  padding: 16px 20px;
}

.domain_table {
  margin-top: 10px;
}

.domain_table table th,
table td {
  border: none;
}

.domain_table
  .el-table
  .el-table__header-wrapper
  .el-table__header
  thead
  tr
  th {
  background-color: #f9f9fc;
}

.domain_table .el-table .el-table__header-wrapper {
  background-color: #f9f9fc;
}

.domain_table .el-table .el-table__fixed .el-table__fixed-header-wrapper {
  background-color: #f9f9fc;
}

.el-table th.el-table__cell {
  background-color: #f9f9fc;
  font-weight: 600;
  color: #333;
  padding: 8px 0;
}

.caozuo {
  color: var(--fontColor);
  cursor: pointer;
}

.caozuo a {
  padding: 0 10px;
  border-right: 1px solid var(--fontColor, #fb6638);
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  color: var(--fontColor);
}

.caozuo a:last-child {
  border-right: none;
}

.empty_data {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.empty_data p {
  margin-top: 16px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
}

/* 添加域名的弹窗 */
.addDoName {
  padding: 16px 20px;
}

.addDoName_input_label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #666;
  line-height: 1;
  padding: 10px 20px 10px 0;
  box-sizing: border-box;
  width: 60px;
}

.addDoName_input_label::before {
  content: "*";
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-family: SimSun;
  font-size: 12px;
  color: #f1495c;
}

.addDoName_input .el-input {
  flex: 1;
}
.instrumentCode {
  /deep/.el-textarea {
    height: 226px;
    .el-textarea__inner {
      height: 226px;
    }
  }
}

.example_code {
  width: 300px;
  margin-left: 10px;
  line-height: 25px;
  .exaple_title {
    line-height: 18px;
    font-size: 13px;
    color: #ccc;
  }
  .exple_sutitle{
    margin-top: 5px;
    line-height: 25px;
  }
}
</style>